import React from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  FiChevronRight,
  FiCoffee,
  FiFilter,
  FiGrid,
  FiKey,
  FiLogOut,
  FiMeh,
  FiShield,
  FiThermometer,
  FiTruck,
  FiUser,
  FiGitBranch,
  FiZapOff,
  FiUserX,
  FiEdit3,
} from "react-icons/fi";
import "../SoleraCss/Responsive.css";
import "../SoleraCss/Solera.css";
import { clearAuth, getSys } from "../services/AuthService";
import Logo from "../assets/nani.png";
import { getRoles } from "@testing-library/react";
import { findRole } from "../services/extras";

function SideBar() {
  let navigate = useNavigate();
  let sys = getSys();

  const logout = async () => {
    await clearAuth();
    window.location.reload();
    navigate("/login");
  };
  return (
    <div>
      <div className="SideDrawer">
        <div className="Solera-SideBar-Logo-Cont">
          <Link to="/dashboard" className="Solera-SideBar-Logo Solera-Logo">
            <img
              src={Logo}
              alt=""
              width="150"
              height="50"
              className="d-inline-block align-text-top"
            />
          </Link>
        </div>

        <div className="Solera-SideBar-Divider">
          <div className="Solera-Admin-Login-Heading">Signed in as</div>
          <div className="Solera-Admin-Login">{findRole(sys?.role)}</div>

          <div>
            <br /> <br />
            {sys?.role === "sys-admin" || sys?.role === "sys-doctor" ? (
              <div className="SideBarSuperHeadingsContainer">
                <Link to="/dashboard" className="Solera-SideBar-Link">
                  <div className="SideBarHeading-Function">
                    <div className="SideBarContG">
                      <FiGrid className="IconReact" />
                      Dashboard
                    </div>{" "}
                    <FiChevronRight />
                  </div>
                </Link>
              </div>
            ) : null}
            {sys?.role === "sys-admin" ||
            sys?.role === "sys-doctor" ||
            sys?.role === "sys-nurse" ||
            sys?.role === "sys-matron" ||
            sys?.role === "sys-pharmacy" ||
            sys?.role === "sys-dentist" ||
            sys?.role === "sys-physiotherapist" ||
            sys?.role === "sys-dietician" ||
            sys?.role === "sys-clinicalsychologist" ||
            sys?.role === "sys-social" ||
            sys?.role === "sys-lap" ? (
              <div className="SideBarSuperHeadingsContainer">
                <Link to="/patients" className="Solera-SideBar-Link">
                  <div className="SideBarHeading-Function">
                    <div className="SideBarContG">
                      <FiUserX className="IconReact" />
                      Patients
                    </div>
                    <FiChevronRight />
                  </div>
                </Link>
              </div>
            ) : null}
            {sys?.role === "sys-admin" || sys?.role === "sys-doctor" ? (
              <div className="SideBarSuperHeadingsContainer">
                <Link to="/doctors" className="Solera-SideBar-Link">
                  <div className="SideBarHeading-Function">
                    <div className="SideBarContG">
                      <FiShield className="IconReact" />
                      Doctors
                    </div>{" "}
                    <FiChevronRight />
                  </div>
                </Link>
              </div>
            ) : null}
            {sys?.role === "sys-admin" || sys?.role === "sys-nurse" ? (
              <div className="SideBarSuperHeadingsContainer">
                <Link to="/nurses" className="Solera-SideBar-Link">
                  <div className="SideBarHeading-Function">
                    <div className="SideBarContG">
                      <FiMeh className="IconReact" />
                      Nurses
                    </div>{" "}
                    <FiChevronRight />
                  </div>
                </Link>
              </div>
            ) : null}
              {sys?.role === "sys-admin" || sys?.role === "sys-nurse" ? (
              <div className="SideBarSuperHeadingsContainer">
                <Link to="/nursesreport" className="Solera-SideBar-Link">
                  <div className="SideBarHeading-Function">
                    <div className="SideBarContG">
                      <FiEdit3 className="IconReact" />
                      Nurse's Report
                    </div>{" "}
                    <FiChevronRight />
                  </div>
                </Link>
              </div>
            ) : null}
            {sys?.role === "sys-admin" || sys?.role === "sys-lap" || sys?.role === "sys-doctor"? (
              <div className="SideBarSuperHeadingsContainer">
                <Link to="/lab-scientist" className="Solera-SideBar-Link">
                  <div className="SideBarHeading-Function">
                    <div className="SideBarContG">
                      <FiFilter className="IconReact" />
                      Laboratory
                    </div>{" "}
                    <FiChevronRight />
                  </div>
                </Link>
              </div>
            ) : null}
            {/* <div className="SideBarSuperHeadingsContainer">
              <Link to="/LabScientist" className="Solera-SideBar-Link">
                <div className="SideBarHeading-Function">
                  <div className="SideBarContG">
                    <FiGrid className="IconReact" />
                    Laboratory
                  </div>{" "}
                  <FiChevronRight />
                </div>
              </Link>
            </div> */}
            {sys?.role === "sys-admin" ? (
              <div className="SideBarSuperHeadingsContainer">
                <Link to="/staff" className="Solera-SideBar-Link">
                  <div className="SideBarHeading-Function">
                    <div className="SideBarContG">
                      <FiUser className="IconReact" />
                      Staff
                    </div>
                    <FiChevronRight />
                  </div>
                </Link>
              </div>
            ) : null}
            {sys?.role === "sys-admin" || sys?.role === "sys-pharmacy" ? (
              <div className="SideBarSuperHeadingsContainer">
                <Link to="/pharmacy" className="Solera-SideBar-Link">
                  <div className="SideBarHeading-Function">
                    <div className="SideBarContG">
                      <FiThermometer className="IconReact" />
                      Pharmacy
                    </div>{" "}
                    <FiChevronRight />
                  </div>
                </Link>
              </div>
            ) : null}
            {/* {
              sys?.role === 'sys-admin' || sys?.role === 'sys-store' ? (
                <div className="SideBarSuperHeadingsContainer">
                 
                </div>
              ) : null
            } */}
            {sys?.role === "sys-admin" || sys?.role === "sys-store" ? (
              <div className="SideBarSuperHeadingsContainer">
                <Link to="/consumables" className="Solera-SideBar-Link">
                  <div className="SideBarHeading-Function">
                    <div className="SideBarContG">
                      <FiGitBranch className="IconReact" />
                      Consumables
                    </div>{" "}
                    <FiChevronRight />
                  </div>
                </Link>
              </div>
            ) : null}
            {sys?.role === "sys-admin" || sys?.role === "sys-pharmacy" || sys?.role === "sys-store" ? (
              <div className="SideBarSuperHeadingsContainer">
                <Link to="/non-consumables" className="Solera-SideBar-Link">
                  <div className="SideBarHeading-Function">
                    <div className="SideBarContG">
                      <FiZapOff className="IconReact" />
                      Non Consumables
                    </div>{" "}
                    <FiChevronRight />
                  </div>
                </Link>
              </div>
            ) : null}
            {sys?.role === "sys-admin" ? (
              <div className="SideBarSuperHeadingsContainer">
                <Link to="/admin" className="Solera-SideBar-Link">
                  <div className="SideBarHeading-Function">
                    <div className="SideBarContG">
                      <FiKey className="IconReact" />
                      Admin
                    </div>{" "}
                    <FiChevronRight />
                  </div>
                </Link>
              </div>
            ) : null}
          </div>
          <div className="SideBarSuperHeadingsContainer">
            <Link to="#" onClick={logout} className="Solera-SideBar-Link">
              <div className="SideBarContG">
                <FiLogOut className="IconReact" />
                Logout
              </div>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SideBar;
