import { React, useEffect, useState } from "react";
import "../../../../SoleraCss/Responsive.css";
import "../../../../SoleraCss/Solera.css";
import Swal from "sweetalert2";
import Dropdown from "react-bootstrap/Dropdown";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { Bars } from "react-loader-spinner";
import AddDoctorNote from "./AddDoctorNote";
import ViewDoctorNoteModal from "./ViewDoctorNoteModal";
import UploadClinicalNote from "./UploadClinicalNote";
import { fetchPatientDocNotes } from "../../../../features/patients/clinical/patientDocNotesSlice";
import ViewDoctorNoteUploadModal from "./ViewDoctorNoteUploadModal";
import { getSys } from "../../../../services/AuthService";

function DoctorNote() {
  const sys = getSys();

  let { id } = useParams();
  const dispatch = useDispatch();
  const { docNotes, loading } = useSelector(
    (state) => state.patientDocNotes
  );

  const [selected, setSelected] = useState({});

  const [viewPreOpNoteDetailsShow, setViewPreOpNoteDetailsShow] =
    useState(false);
  const handleViewPreOpNoteDetails = () => setViewPreOpNoteDetailsShow(false);

  const [uploadNotesShow, setUploadNotesShow] = useState(false);
  const handleUploadNoteDetails = () => setUploadNotesShow(false);

  const [viewDoctorNoteUploadShow, setViewDoctorNoteUploadShow] =
    useState(false);
  const handleViewDoctorNoteUpload = () => setViewDoctorNoteUploadShow(false);

  const [editPreOpNoteDetailsShow, setEditPreOpNoteDetailsShow] =
    useState(false);
  const handleEditPreOpNoteDetails = () => setEditPreOpNoteDetailsShow(false);

  
  useEffect(() => {
    if (id) {
      dispatch(fetchPatientDocNotes({ patientId: id, type: "doctor" }));
    }
  }, [id]);

  const showDate = (passDate) => {
    return moment(passDate).format("DD-MM-YYYY");
  };

  return (
    <div>
      {sys?.role === "sys-admin" || sys?.role === "sys-doctor" ? (
        <>
          <div className="Report-Notes">
            <div className="ReportHeader">
              <div className="Report-Heading">Doctor's Note</div>
              <div className="Report-Count">
                <span>0</span>&nbsp;Doctor's Note
              </div>
              <AddDoctorNote />
            </div>
          </div>
          <br />
          {loading ? (
            <div className="d-flex justify-content-center">
              <Bars
                heigth="30"
                width="30"
                color="gray"
                ariaLabel="loading-indicator"
              />
            </div>
          ) : null}

    {docNotes?.map((item, index) => (
        <div key={index} className="Patient-Record-Container">
          <div className="Patient-Record-Wrapper">
            <div className="PatientNote-C">
              <div className="Wallet-Ref-Heading">Type of Note</div>
              <div className="Wallet-Ref-Result">{item?.subject || 'No subject'}</div>
            </div>
            <div className="PatientNote-C">
              <div className="Wallet-Ref-Heading">Action</div>
              <div className="Wallet-Ref-Result">{item?.action_note || 'No Action'}</div>
            </div>

            <div className="PatientNote-C">
              <div className="Wallet-Ref-Heading">Doctor's Name</div>
              <div className="Wallet-Ref-Result">{item?.user?.full_name}</div>
            </div>
            <div className="PatientNote-C">
              <div className="Wallet-Ref-Heading">Date</div>
              <div className="Wallet-Ref-Result">
                {showDate(item?.created_at)}
              </div>
            </div>

            <div
              className="collapse navbar-collapse"
              id="bs-example-navbar-collapse-1"
            >
              <ul className="nav navbar-nav">
                <li className="active">
                  <a href="#">Home</a>
                </li>

                <li className="dropdown">
                  <a
                    href="#"
                    className="dropdown-toggle"
                    data-toggle="dropdown"
                  >
                    Menu <b className="caret"></b>
                  </a>
                  <ul className="dropdown-menu multi-level">
                    <li>
                      <a href="#">Level 1</a>
                    </li>
                    <li className="dropdown-submenu">
                      <a
                        href="#"
                        className="dropdown-toggle"
                        data-toggle="dropdown"
                      >
                        Level 1
                      </a>

                      <ul className="dropdown-menu">
                        <li>
                          <a href="#">Level 2</a>
                        </li>
                        <li className="dropdown-submenu">
                          <a
                            href="#"
                            className="dropdown-toggle"
                            data-toggle="dropdown"
                          >
                            Level 2
                          </a>

                          <ul className="dropdown-menu">
                            <li>
                              <a href="#">Level 3</a>
                            </li>
                            <li className="dropdown-submenu">
                              <a
                                href="#"
                                className="dropdown-toggle"
                                data-toggle="dropdown"
                              >
                                Level 3
                              </a>

                              <ul className="dropdown-menu">
                                <li>
                                  <a href="#">Level 4</a>
                                </li>
                                <li>
                                  <a href="#">Level 4</a>
                                </li>
                                <li>
                                  <a href="#">Level 4</a>
                                </li>
                              </ul>
                            </li>
                          </ul>
                        </li>
                      </ul>
                    </li>
                  </ul>
                </li>
              </ul>
            </div>

            <div className="PatientNote-C">
              <div className="Wallet-Ref-Heading">Status</div>
              <div className="Wallet-Ref-Result">{item?.status}</div>
            </div>
            <div className="Action-Drop-Down">
              <Dropdown>
                <Dropdown.Toggle id="dropdown-basic" className="Action-Drop">
                  Manage Note
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  <Dropdown.Item
                    onClick={() => {
                      setSelected(item);
                      setViewPreOpNoteDetailsShow(true);
                    }}
                    className="Solera-Patients"
                  >
                    View doctor's note
                  </Dropdown.Item>
                  <Dropdown.Item
                    onClick={() => {
                      setSelected(item);
                      setUploadNotesShow(true);
                    }}
                    className="Solera-Patients"
                  >
                    Upload doctor's Note
                  </Dropdown.Item>
                  <Dropdown.Item
                    onClick={() => {
                      setSelected(item);
                      setViewDoctorNoteUploadShow(true);
                    }}
                    className="Solera-Patients"
                  >
                    View uploaded doctor's note
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </div>
          </div>
        </div>
      ))}
        </>
      ) : null}

    

      <div>
        <ViewDoctorNoteModal
          selected={selected}
          show={viewPreOpNoteDetailsShow}
          onClose={handleViewPreOpNoteDetails}
          onOpen={() => setViewPreOpNoteDetailsShow(true)}
        />

        <UploadClinicalNote
          named={"doctor"}
          selected={selected}
          show={uploadNotesShow}
          onClose={handleUploadNoteDetails}
          onOpen={() => setUploadNotesShow(true)}
        />

        <ViewDoctorNoteUploadModal
          selected={selected}
          show={viewDoctorNoteUploadShow}
          onClose={handleViewDoctorNoteUpload}
          onOpen={() => setViewDoctorNoteUploadShow(true)}
        />

        {/* <EditPhysioModal
          show={editPreOpNoteDetailsShow}
          onClose={handleEditPreOpNoteDetails}
          onOpen={() => setEditPreOpNoteDetailsShow(true)}
        /> */}
      </div>
    </div>
  );
}

export default DoctorNote;
